<!--
 * @Descripttion: 客服统计
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:45:03
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-22 18:21:23
-->
<template>
    <div class="serviceStatistics">
        <div class="ss-title-screen">
            <div class="sr-item">
                <el-select v-model="years" @change="changeYear">
                    <el-option
                    v-for="item in yearArray"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="month" @change="changeMonth">
                    <el-option
                    v-for="item in monthArray"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="ss-content">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="联系方式"></el-table-column>
                <el-table-column label="培训绩效金">
                    <template slot-scope="scope">
                        <div>{{scope.row.performance_price}}({{scope.row.performance_rate}}%)</div>
                    </template>
                </el-table-column>
                <el-table-column prop="price" label="服务提成">
                    <template slot-scope="scope">
                        <div>{{scope.row.commission_price}}({{scope.row.num}}个)</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'serviceStatistics',
            yearArray: [],
            years: '',
            monthArray: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月',],
            month: '',
            tableData: []
        }
    },
    mounted () {
        // 获取最近十年的年份数组
        var currentYear = new Date().getFullYear();
        var yearArray = [];
        var yearArr = [];
        for (var i = 0; i < 10; i++) {
            yearArray.push((currentYear - i)+'年');
            yearArr.push((currentYear - i));
        }
        this.years = yearArray[0]
        this.yearData = yearArr[0]
        this.yearArray = yearArray;

        // 获取当前的月份
        var currentDate = new Date();
        var currentMonth= currentDate.getMonth() +1;
        this.month = currentMonth + '月'
        this.monthData = currentMonth

        this.getServiceCount()
    },
    methods: {
        // 选择月
        changeMonth(){
            this.monthData = this.month.replace('月', '');
            this.getServiceCount()
        },
        // 选择年
        changeYear(){
            this.yearData = this.years.replace('年', '');
            this.getServiceCount()
        },
        // 获取销售统计数据
        getServiceCount(){
            let params = {
                page_num: 1,
                page_size: 100,
                year: this.yearData,
                month: this.monthData,
            }
            api.get('/service/service/service_count', params, (res) =>  {
                this.tableData = res.data.list
            });
        },
    },
}
</script>

<style lang='scss'>
.serviceStatistics{
    .ss-title-screen{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 15px 40px 0 40px;
        .sr-item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 30px;
            padding-bottom: 15px;
            .i-title{
                font-size: 16px;
                color: #0a032d;
            }
            .el-select{
                width: 150px;
                padding-left: 20px;
            }
        }
    }
    .ss-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                padding: 0 15px;
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
    }
}
</style>
